import React, { useRef, useState , useEffect  } from "react";

//  import Button from "components/shared/Button/Button";
import Input from "components/shared/Inputs/Inputs";
import SearchTable from "components/shared/SearchTable/SearchTable";
import { useNavigate, useParams } from "react-router-dom";


import api from "../../services/api";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Col, Card, CardBody, Container, Row, Button, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, Table} from 'reactstrap';

import VerMorador from './VerMorador';
import Reenviar from './Reenviar';
import AuthService from "../../services/Auth.service";
// import PersonAddIcon  from '@mui/icons-material/PersonAdd';
// import DomainAddIcon  from '@mui/icons-material/DomainAdd';

// import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

const ListaMoradores = () => {

  const { id } = useParams();
  const initialState = {
    number: "",
    desc: "",
    model: "",
    status: "",
  };


  let navigate = useNavigate();


  const user = AuthService.getCurrentUser();

  let checkempresa = null;
  
  if(user != null){
  if(user.empresa[0] != undefined){
    checkempresa = user.empresa[0].OID_EMPRESA
    
  }
  }

  const [showModal, setShowModal] = useState(false);
 

  const modalRef = useRef();

  const openModal = () => {
 
    setShowModal(true);
  };

  const [employees, setEmployees] = useState([]);
  const handleShow = e => {
   
      setShowModal(true);
    
  };

//   function updateEmployee(id, newName, newRole) {
//     const updatedEmployees = employees.map((employee) => {
//         if (id == employee.id) {
//             return { ...employee, name: newName, role: newRole };
//         }

//         return employee;
//     });
//     setEmployees(updatedEmployees);
// }
 
  const [search, setSearch] = useState(initialState);
  const [values, setValues] = useState([]);
  const [showList, setShowList] = useState(false);
  const [data, setData] = useState([])
  const [modal, setmodal] = useState(false);
  const [modalida, setmodalida] = useState(false);
  const [imovel, setImovel] = useState({})
  const [razao, setRazao] = useState("")
  const [sindico, setSindico] = useState("")
  const [ida, setIda] = useState("")
  const [Imovelcd, setImovelcd] = useState("")

  const [idu, setId] = useState(0)

  const [password, setPassword] = useState('');
  const [passwordEntered, setPasswordEntered] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    if (password === 'token') {
      setPasswordEntered(true);
    } else {
      alert('Senha inválida'); // You can replace alert with a more sophisticated error handling approach
    }
  };



  const toggleViewModal = (idu) => {
    setId(idu);
    setmodal(!modal);
    console.log(idu)
  };


  useEffect(() => {
    if (idu !== 0) {
		api.get('/imovel/user/imovel/'+idu).then((response) => {
			let body = {
        nome: response.data[0].DS_NOME +" "+ response.data[0].DS_SOBRENOME,
        email: response.data[0].DS_EMAIL,
        telefone: response.data[0].NR_TELEFONE,
        NR_IDA: response.data[0].NR_IDA,
        medidor: response.data[0].NR_MEDIDOR,
        oid_medidor : response.data[0].medidorid,
        PORTA: response.data[0].PORTA,
        TP_MEDIDOR: response.data[0].TP_MEDIDOR,
        Pulso: response.data[0].NR_CONSUMO_LITROS,
        DS_RELOJOARIA_INICIAL: response.data[0].DS_RELOJOARIA_INICIAL,
        DS_DESCRICAO_EQUIPAMENTO: response.data[0].DS_DESCRICAO_EQUIPAMENTO,
        DS_MODELO_MEDIDOR: response.data[0].DS_MODELO_MEDIDOR,
      };
      setImovel(body)
      console.log(response.data[0])
          console.log(imovel)
         
		});
  }
	}, [idu]);


  useEffect(() => {
    if (idu !== 0) {
		api.get('/imovel/user/imovel/'+idu).then((response) => {
			let body = {
        nome: response.data[0].DS_NOME +" "+ response.data[0].DS_SOBRENOME,
        email: response.data[0].DS_EMAIL,
        telefone: response.data[0].NR_TELEFONE,
        NR_IDA: response.data[0].NR_IDA,
        medidor: response.data[0].NR_MEDIDOR,
        oid_medidor : response.data[0].medidorid,
        PORTA: response.data[0].PORTA,
        TP_MEDIDOR: response.data[0].TP_MEDIDOR,
        Pulso: response.data[0].NR_CONSUMO_LITROS,
        DS_RELOJOARIA_INICIAL: response.data[0].DS_RELOJOARIA_INICIAL,
        DS_DESCRICAO_EQUIPAMENTO: response.data[0].DS_DESCRICAO_EQUIPAMENTO,
        DS_MODELO_MEDIDOR: response.data[0].DS_MODELO_MEDIDOR,
      };
      setImovel(body)
      console.log(response.data[0])
          console.log(imovel)
         
		});
  }
	}, [idu]);

  const [editModeRow, setEditModeRow] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [editedBloco, setEditedBloco] = useState('');
  const [editedIda, setEditedIda] = useState('');




  



  const handleInputIda = (event) => {
    setEditedIda(event.target.value);
  };


  const handleSaveida = () => {
    setmodalida(!modalida);
    // Exit edit mode
    // Perform save logic here
    let data = {
    
        
      CD_IMOVEL: editedIda
    }
  
  

    
    api.put("/imovel/"+Imovelcd, data ).then(() => {
        Swal.fire({
          icon: "success",
          title: "Número Alterado!",
        })
          .then(() => {
            navigate(`/listaMoradores/${id}`);
            window.location.reload(true)
          })
          .catch(() => {
            Swal.fire({
              icon: "warning",
              title: "Erro!",
            });
          });
      });
  
  };

  const handleEditClick = (arr) => {
    setEditedName(arr.comp);
    setEditedBloco(arr.bloco)
    setEditModeRow(arr); // Set the row to edit mode
  };

  const handleSave = (arr) => {
    setEditModeRow(null); // Exit edit mode
    // Perform save logic here
    let data = {
    
        
      IM_COMPLEMENTO: editedName ? editedName : "" ,
      IM_BLOCO: editedBloco ? editedBloco : ""
    }
  
  
    
    api.put("/imovel/lista/"+arr.OID_IMOVEL, data ).then(() => {
        Swal.fire({
          icon: "success",
          title: "Nome Alterado!",
        })
          .then(() => {
            navigate(`/listaMoradores/${id}`);
            window.location.reload(true)
          })
          .catch(() => {
            Swal.fire({
              icon: "warning",
              title: "Erro!",
            });
          });
      });
  
  };

  useEffect(() => {
		api.get('/imovel/'+id).then((response) => {
			let arr = [];
			arr = formatArray(response.data);
			setData(arr)
			setValues(arr);
		});
	}, []);

  useEffect(() => {
		api.get('/empresa/dados/'+id).then((response) => {
			
			console.log(response.data[0].NM_RAZAO_SOCIAL+"empresa")
			setRazao(response.data[0].NM_RAZAO_SOCIAL)

      if(response.data[0].DS_NOME)
      {
        setSindico(response.data[0].DS_NOME)
      }
      if(response.data[0].CD_IMOVEL)
      {
        setIda(response.data[0].CD_IMOVEL)
      }
      if(response.data[0].OID_IMOVEL)
        {
          setImovelcd(response.data[0].OID_IMOVEL)
        }
		});
	}, [id]);


  const toggleViewModalIda = () => {
    setEditedIda(ida);
    setmodalida(!modalida);
   
  };


  const [setp, setCalibp] = useState(initialState)
  const [edit, setEdit] = useState([])


  const handleClick = calib => {

    setCalibp({ ...initialState, searched: true })

    // setRelojio({
    //   reloj: calib.clock,
    // })

    setEdit({
      OID_REGISTRO_PORTA_MEDIDOR: calib.idEquipDmae,
      lora: calib.ui,
      porta: calib.porta,
      watch: calib.pulse_ini ? calib.pulse_ini : "0",
    })

    setTimeout(
      window.scrollTo({
        // top: 0,
        top: 6000,
        behavior: "smooth",
      }),
      1000
    )
  }



  const onChangeHandler = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (!search.number && !search.desc && !search.model && !search.status) {
      Swal.fire({
        icon: "warning",
        text: "Preencha ao menos um campo para fazer uma pesquisa",
      });
      return;
    }

    if (!search.number && (search.desc || search.model) && !search.status) {
      Swal.fire({
        icon: "warning",
        text: "Preencha o campo Status para fazer uma pesquisa sem Número Hidrômetro",
      });
      return;
    }

    let body = {
      NR_HIDROMETRO: search.number,
      DS_DESCRICAO_HIDROMETRO: search.desc,
      DS_MODELO_HIDROMETRO: search.model,
      TP_ATIVO: search.status,
    };

    api
      .post("/hydrometers/filter", body)
      .then((response) => {
        let arr = formatArray(response.data);
        if (arr.length === 0) {
          Swal.fire({
            icon: "warning",
            text: "Nenhum resultado encontrado, refaça a sua busca!",
          });
          setShowList(false);
        } else {
          Swal.fire({
            icon: "success",
            timer: 500,
          });
          arr.map((elem) => {
            if (elem.status === "S") {
              elem.status = "Ativo";
            }
            if (elem.status === "N") {
              elem.status = "Inativo";
            }
          });

          console.log(arr);
          setValues(arr);
          setSearch(initialState);
          setShowList(true);
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "warning",
          text: "Erro Tente Novamente!",
        });
        setShowList(false);
      });
  };

  const formatArray = (data) => {
    let arr = [];
    data.map((elem) => {
      arr.push({
        imovel: elem.imovel,
        OID_IMOVEL: elem.OID_IMOVEL,
        nome: elem.DS_NOME,
        sobrenome: elem.DS_SOBRENOME,
        email: elem.DS_EMAIL,
        telefone: elem.NR_TELEFONE,
        status: elem.DS_STATUS,
        id: elem.OID_USUARIO,
        comp: elem.IM_COMPLEMENTO,
        bloco: elem.IM_BLOCO
      });
    });
    return arr;
  };

  const [selectedNumber, setSelectedNumber] = useState(null);

    const [showPopup, setShowPopup] = useState(false);


  const handleButtonClick = (number) => {
    // Prevent default form submission
   setSelectedNumber(number);
   setShowPopup(true);
 };

 const handleConfirm = () => {
   // Perform your function logic here
   console.log('Function executed with number:', selectedNumber);
   setShowPopup(false); 

   let bodyreg = {
     
     OID_IMOVEL: selectedNumber,
     
   }

   api.post("/imovel/filter", bodyreg).then(response => {
     console.log(response.data.length+"len")
     if (response.data.length > 0) {
      
       Swal.fire({
         icon: "warning",
         title: "O Imóvel está vinculado a um medidor; antes de deletar o imóvel, remova o vínculo.",
       });
     } 
     else{
       api.delete(`/imovel/${selectedNumber}`).then(() => {
         Swal.fire({
           icon: "success",
           title: "imóvel deletado!",
         })
           .then(() => {
            navigate(`/listaMoradores/${id}`);
             window.location.reload(true)
           })
           .catch(() => {
             Swal.fire({
               icon: "warning",
               title: "Dados Inválidos!",
             });
           });
       });

      
     }
   })


 };

 const handleCancel = () => {
   setShowPopup(false); // Close the popup without executing the function
 };



  
  return (
    
    <React.Fragment>
      
      <div className="page-content">
        
        <label id="component-title">Moradores | {razao}</label>

        <div>

        <Link
                      color=""
                      className="btn "
                      
                      to={`/condominios/`}

                      > <i className="mdi mdi-chevron-left" /> Voltar </Link>
                      </div>

     
        <form className="component-form container" style={{ margin: "0px", maxWidth:"100%"}}>
          <div className="container" style={{ margin: "0px", maxWidth:"100%"}}>
            <div className="row" style={{  padding:"10px 10px 15px 0px"}}>
              
              <div className="col-lg-4 card card-body" style={{  padding:"10px 10px 15px 0px", background:"#f8f8fb", boxShadow:"none"}} >
              <Link to={`/cadastro/imovel-cadastro/${id}`} style={{  maxWidth:"150px"}}> 
                <Button
                color="primary"
                className="btn-md  btn-rounded"
               
                  
                  
                >Cadastrar IMÓVEL </Button>
                </Link>
                
              </div>
              {sindico ?
                <>
               <div className="col-lg-4 card card-body" style={{  padding:"10px 10px 15px 0px", background:"#f8f8fb", boxShadow:"none"}} >
              Síndico: {sindico}
              </div>
              </>
                : 

                <div className="col-lg-4 card card-body" style={{  padding:"10px 10px 15px 0px", background:"#f8f8fb", boxShadow:"none"}} >
              <Link to={`/cadastro/SindicoUpdate/${id}` }  style={{ marginLeft:"10%", maxWidth:"150px"}}>
                <Button
                color="primary"
                className="btn-md  btn-rounded"
               
                  
                  
                >Cadastrar Síndico </Button>
                </Link>
                
              </div>
                
                
                }

                

           
                <div className="col-lg-4 card card-body" style={{ background:"#f8f8fb", boxShadow:"none", flexDirection:"row",top:"5%"}} >
                <div style={{ top:"5%"}}>Cód. Imóvel: {ida} </div> <a
                              style={{ left: "5%", bottom:"15%" }}
                              type="button"
                              onClick={toggleViewModalIda}
                              color="link"
                              size="sm"
                              className="btn btn-light waves-effect waves-light"
                            >
                              <i className="dripicons-document-edit" />
                            </a>
                </div>
                
                
                
            </div>
           
            
           
            
          </div>
        </form>
        {/* <SearchTable
          tableHead={[
            "Nome",
            
            "",
          ]}
          tableBody={[values, 1]}
          showTable={showList}
        >
          {values?.map((maker, key) => (
            <td key={key}>
              <Link
                type="button"
                to={`/cadastro/hidrometro-update/${maker.id}`}
                color="link"
                size="sm"
                className="btn-light waves-effect waves-light"
              >
                <i className="dripicons-document-edit" />
              </Link>
            </td>
          ))}
        </SearchTable> */}
        <div className="table-responsive">
						<table className="table align-middle table-nowrap mb-0">
							<thead className="table-light">
								<tr>
                  
									<th className="align-middle">
                  IMÓVEL
									</th>
									<th className="align-middle">PROPRIETÁRIO/INQUILINO</th>
                 
									<th className="align-middle">STATUS</th>
									<th className="align-middle"></th>
									<th className="align-middle"></th>
								</tr>
							</thead>
							<tbody>
                {values.length >0 ? 
								values?.map((arr, key) => (
									<tr key={'_tr_' + key}>
                    
                    <td style={{  }}>



                    {(() => {
                      if (editModeRow === arr) {
                        // In edit mode, display an input field or any other editable component
                        return (
                          <>
                            <input
                              type="text"
                              value={editedName}
                              
                              onChange={(e) => setEditedName(e.target.value)} // Set the state variable
                              style={{ minWidth: '150px', maxWidth: '50%', marginRight: '10px' }}
                            />
                            <input
                              type="text"
                              value={editedBloco}
                              // placeholder="Bloco"
                              
                              onChange={(e) => setEditedBloco(e.target.value)} // Set the state variable
                              style={{ minWidth: '150px', maxWidth: '50%' }}
                            />
                            <button
                          style={{ marginLeft: "3%" }}
                          className="btn btn-outline-secondary"
                          onClick={() => handleSave(arr)} // Pass a reference to handleSave
                        >
                          Salvar
                        </button>
                          </>
                        );
                      } else {
                        return (
                          <span className="">
                            {arr.imovel}
                            <a
                              style={{ left: "5%" }}
                              type="button"
                              onClick={() => handleEditClick(arr)}
                              color="link"
                              size="sm"
                              className="btn btn-light waves-effect waves-light"
                            >
                              <i className="dripicons-document-edit" />
                            </a>
                          </span>
                          
                        );
                      }
                    })()}


  {/* {arr.imovel}
  <a
                                  style={{ left: "5%" }}
                                  type="button"
                                  onClick={() => {
                                    handleClick(arr)
                                  }}
                                  color="link"
                                  size="sm"
                                  className="btn btn-light waves-effect waves-light"
                                >
                                  <i className="dripicons-document-edit" />
                                </a> */}
                    </td>
										<td>
                    {arr.nome == null ? (
                      <Link 
                     
                      to={`/cadastro/moradores-cadastro/${arr.OID_IMOVEL}/admin`}
                      >
                      <Button
                      color="primary"
                      className="btn-sm btn-rounded"

                      >Cadastrar Morador   </Button>
                      </Link>
												//  <span
                        //  className="reenviar"> <br></br> Adicionar   <PersonAddIcon/>
                        //              </span>
											) : (
                        <span>
												{arr.nome} {arr.sobrenome}
												</span>
											)}
                      
                      
                      
                      </td>
                   
										<td>
                      
                      {
                      (() => {
                        if(arr.status == null && arr.nome != null){
                          return  <span className="badge bg-success">
													Cadastro Completo
												</span>
                        }
                        if(arr.status == 1 && arr.nome != null){
                          return <span className="badge bg-info bg-primary">
													E-mail enviado
												</span>  
                        }
                        if(arr.status == null && arr.nome == null){
                         return <span className="badge bg-info bg-warning">
													Aguardando Cadastro
												</span>  
                        }
                      })()
                    }
                                            
											
                      {/* {arr.status == 1 ? (
                       
												  <Reenviar
                          id={arr.OID_IMOVEL}
                          name={arr.nome}
                          role={arr.role}                          
                      />           
                       
											) : null } */}

										</td>
										<td>				
                    <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => toggleViewModal(arr.OID_IMOVEL)}
            >
              Ver Dados
            </Button>
                      {/* <VerMorador
                                    id={arr.OID_IMOVEL}
                                    name={arr.nome}
                                    role={arr.role}
                                    
                                /> */}
										</td>

                    
                  <td>
                  <i onClick={() => handleButtonClick(arr.OID_IMOVEL)} className="dripicons-trash" style={{ cursor: 'pointer' }} /> 
                  </td>
									</tr>
								)) : <tr><td>Nenhum Registro, Cadastre um Morador </td></tr>}
							</tbody>
						</table>
					</div>
          

          {showPopup && (
                    <div className="modal-overlay">
                      <div className="modal-content2">
                        <p>Você realmente deseja deletar o Imóvel?</p>
                        <div className="modal-buttons">
                          <button className="confirm-button" onClick={handleConfirm}>Sim</button>
                          <button className="cancel-button" onClick={handleCancel}>Não</button>
                        </div>
                      </div>
                    </div>
                  )}
      </div>
     
      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
           Informações Imóvel
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
            Email: <span className="text-primary">{imovel.email}</span>
            </p>
            <p className="mb-4">
            telefone: <span className="text-primary">{imovel.telefone ? imovel.telefone : "Aguardando Preenchimento do Morador"}</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Dados Imóvel</th>
                    <th scope="col"></th>
                    {/* <th scope="col">Price</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        {/* <img src={modalimage1} alt="" className="avatar-sm" /> */}
                        IDA
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                        {imovel.NR_IDA ? imovel.NR_IDA : "Aguardando Preenchimento do Morador"}
                        </h5>
                       
                      </div>
                    </td>
                 
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                      Medidor: 
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                        {imovel.medidor ? imovel.medidor : "Aguardando Preenchimento do Morador"}

                        {imovel.medidor ?
                  <>
                  <Link
                      type="button"
                      to={`/cadastro/hidrometro-update/${imovel.oid_medidor}`}
                      color="link"
                      style={{ float: "right" }}
                      size="sm"
                      className="btn-light waves-effect waves-light"
                    >
                      <i className="dripicons-document-edit" />
                    </Link>
                    </> : null
                    }
                        </h5>

                        
                       
                      </div>
                    </td>
                   
                  </tr>
                 
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Fechar
            </Button>
          </ModalFooter>


      
        </div>
      </Modal>

      <Modal
        isOpen={modalida}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodalida(!modalida);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodalida(!modalida);
            }}
          >
           
           Código do imóvel</ModalHeader>
          <ModalBody>
            <p className="mb-2">
          
            </p>
         
            <div>
      {!passwordEntered ? (
        <>
        <div className="password-input">
          <Input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={handlePasswordChange}
          />
          {/* <button className="confirm-button" onClick={handlePasswordSubmit} >Entrar</button> */}
          {/* <button onClick={handlePasswordSubmit}>Submit</button> */}
        </div>
        <div style={{ margin:"5px" }}>

        <button className="confirm-button"   onClick={handlePasswordSubmit} >Entrar</button>

        </div>
       
         </>
      ) : (
        <div className="table-responsive">
          <Table className="table table-centered table-nowrap">
            <thead>
              <tr>
                <th scope="col">Editar Número</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    <Input
                      id="number"
                      value={editedIda}
                      onChange={handleInputIda}
                      styles={{ width: '100%' }}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </div>
          </ModalBody>
          <ModalFooter>
          {passwordEntered ? (
          <button className="confirm-button" onClick={handleSaveida} >Salvar</button>
        ) : (

          "" )}
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodalida(!modalida);
              }}
            >
              Fechar
            </Button>
          </ModalFooter>


      
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ListaMoradores;
