import axios from "axios";

// location.hostname === "localhost" || location.hostname === "127.0.0.1"
// console.log(location.hostname)

const api = axios.create({

baseURL: 'backendiot'
  //baseURL: 'http://localhost:3333/'
  //baseURL: 'https://iotmonitor-api-hml.syskeeper.com.br/'

});

const user = JSON.parse(localStorage.getItem("user"));

if(user){

api.defaults.headers.common['authorization'] = `${user.token}`;
}
// location.hostname === "localhost" || location.hostname === "127.0.0.1"
// console.log(location.hostname)
//baseURL: 'http://localhost:3333/',
// const api = axios.create({

//   baseURL:
//   location.hostname === "localhost" 
//   ? "https//iotmonitor-api-hml.syskeeper.com.br/"
//   : 'iotmonitor-api',



// });








export default api;
